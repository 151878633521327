import React from "react"
import "./404.scss"
import { Button } from "primereact/button";

const Page404 = () => {
    return (
        <div className={'page__404'}>
            <div className="centered-block">
                <h1>404</h1>
                <h2>Такой страницы не существует</h2>
                <p>Попробуйте повторить действие или обратитесь в поддержу.</p>
                <br/>
                <div className={'error__button'}>
                    <Button label='Вернуться на главную' onClick={() => {
                        window.location.href = '/';
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default Page404;