import { observable, action } from "mobx";
import notificationService from '../services/notification';
import autoServicesService from "../services/services";
import integrationService from "../services/integration";
import scoreService from "../services/score";


class NotificationStore {
    #notificationService;
    #autoServicesService;
    #integrationService;
    #scoreService;

    /*
    TODO убрать в свойствах и методах привязки к email/telegram сделать более универсальными

        emailNotification
        setEmailNotification
        parseEmailSetting
        getEmailNotificationSetting
        getTelegramNotificationSetting
        getEmptyEmailNotification
     */
    @observable emailNotification = {
        "policy": {
            "types": [],
            "scores": [],
            "sources": [],
            "branches": []
        },
        "accounts": [],
        "enabled": true,
        "schedule": {
            "period": "once-hour"
        }
    };

    typesNotification = [
        { value: 'ext_review', title: 'Отзывы из мониторинга', descr: 'Получение уведомлений об отзывах поступающих на ваши онлайн площадки.' },
        { value: 'app_review', title: 'Отзывы из проактивного сбора', descr: 'Получение уведомлений об отзывах полученных через функционал «Проактивного сбора отзывов».' },
        { value: 'qr_review', title: 'Отзывы из QR-кодов', descr: 'Получение уведомлений о перехваченных негативных отзывах полученных через QR-коды.' },
    ];

    periodicityList = [
        { value: 'once-hour', label: '1 раз в час' },
        { value: 'once-three-hours', label: '1 раз в 3 часа' },
        { value: 'once-day-afternoon', label: '1 раз в день (в 15:00)' },
    ];


    constructor(notificationService, autoServicesService, integrationService) {
        this.#notificationService = notificationService;
        this.#autoServicesService = autoServicesService;
        this.#integrationService = integrationService;
        this.#scoreService = scoreService;
    }

    @action setEmailNotification = (object) => {
        this.emailNotification = { ...object };
    }

    @action addValue = (field, values) => {
        //TODO передеать значение обьекта (scores,sources и тд) сделат динамичиским
        switch (field) {
            case 'rating':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        policy: {
                            ...this.emailNotification.policy,
                            ...{ scores: values }
                        }
                    }
                };
                break;

            case 'sources':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        policy: {
                            ...this.emailNotification.policy,
                            ...{ sources: values }
                        }
                    }
                }
                break;

            case 'branches':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        policy: {
                            ...this.emailNotification.policy,
                            ...{ branches: values }
                        }
                    }
                }
                break;

            case 'email':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        accounts: values.split(',')
                    }
                }
                break;

            case 'periodicity':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        schedule: {
                            ...this.emailNotification.schedule,
                            ...{ period: values }
                        }
                    }
                }
                break;

            case 'types':
                this.emailNotification = {
                    ...this.emailNotification,
                    ...{
                        policy: {
                            ...this.emailNotification.policy,
                            ...{ types: values }
                        }
                    }
                }
                break;

            default:
        }
    }

    getIdFromObject(obj) {
        let ids = [];
        obj.map((o) => {
            ids = [...ids, o.id];
        })
        return ids;
    }

    parseEmailSetting(data) {
        const policyTypes = [...data.policy.types.map(t => {
            return t.code;
        })]

        const policySources = [...data.policy.sources.map(s => {
            return s.code;
        })]

        const policyBranches = [...data.policy.branches.map(b => {
            return b.id;
        })]

        const accounts = [...data.accounts.map(a => {
            return a.username;
        })]

        return {
            ...data,
            ...{
                policy: {
                    ...data.policy,
                    ...{ types: policyTypes },
                    ...{ sources: policySources },
                    ...{ branches: policyBranches }
                },
                ...{ accounts: accounts }
            }
        }
    }

    saveNotificationSetting(dest = 'email') {

//TODO вынести в метод
        this.emailNotification.enabled = true;
        delete this.emailNotification.connected;
        delete this.emailNotification.schedule.immediately;
        if (this.emailNotification.accounts[0] === '') {
            this.emailNotification.accounts = [];
        }

        if (dest === 'email') {
            return this.#notificationService.saveEmailNotificationSetting(this.emailNotification)
                .then(result => result)
                .catch(e => {
                    return Promise.reject(e)
                });

        } else if (dest === 'telegram') {
            return this.#notificationService.saveTelegramNotificationSetting(this.emailNotification)
                .then(result => result)
                .catch(e => e);
        }
    }

    getEmailNotificationSetting() {
        this.setEmailNotification(this.getEmptyEmailNotification())

        return this.#notificationService.getEmailNotificationSetting()
            .then(result => {
                if (result) {
                    const data = this.parseEmailSetting(result);
                    this.setEmailNotification(data)
                    return data;

                } else {
                    return {}
                }
            })
            .catch(e => e)
    }

    getTelegramNotificationSetting() {
        this.setEmailNotification(this.getEmptyEmailNotification())

        return this.#notificationService.getTelegramNotificationSetting()
            .then(result => {
                if (result) {
                    const data = this.parseEmailSetting(result);
                    this.setEmailNotification(data)
                    return data;
                } else {
                    return {}
                }
            })
            .catch(e => e)
    }

    getBranchList() {
        return this.#autoServicesService.getBranches()
            .then(result => {
                return result.map(r => ({
                        value: r.id,
                        label: r.title,
                    }
                ))
            })
            .catch(e => e);
    }

    getSourcesList() {
        return this.#integrationService.getSources()
            .then(result => {
                return result.items
                    .filter(r => r.code !== 'okreview')
                    .map(r => ({
                        value: r.code,
                        label: r.title
                    }))
            })
            .catch(e => e);

    }

    getScore() {
        return this.#scoreService.getScore()
            .then(result => {
                let score = result.map(r => (
                    {
                        value: r.id,
                        label: r.title
                    }
                ));

                score.unshift({
                    value: 0,
                    label: 'Без рейтинга',
                })
                return score;
            })
    }

    markCountInStore() {
        this.#notificationService.markCountInStore();
    }

    getEmptyEmailNotification() {
        return {
            "policy": {
                "types": [],
                "scores": [],
                "sources": [],
                "branches": []
            },
            "accounts": [],
            "enabled": true,
            "schedule": {
                "period": "once-hour"
            }
        }
    }

}

const notificationStore = new NotificationStore(notificationService, autoServicesService, integrationService, scoreService);
export default notificationStore;
